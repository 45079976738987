<template>
  <a-card>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.order_no" placeholder="请输入订单号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="count_render" slot-scope="text" style="text-align: right; width: 100%; padding-right: 0px">
        {{ text | numberFormat}}
      </div>
    </s-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Base64 } from 'js-base64'
import { wechat_refund_order_goods_list } from '@/api/c_wms_wechat_counter_order'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      select_status_visible: false,
      select_edit_record: {},
      select_status_data: '',
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '仓库',
          ellipsis: true,
          dataIndex: 'warehouse'
        },
        {
          title: '库位',
          ellipsis: true,
          dataIndex: 'cargo_space'
        },
        {
          title: '订单号',
          ellipsis: true,
          dataIndex: 'order_no'
        },
        {
          title: '商品条形码',
          ellipsis: true,
          align: 'center',
          dataIndex: 'barcode'
        },
        {
          title: '数量',
          width: 150,
          align: 'right',
          dataIndex: 'count',
          scopedSlots: { customRender: 'count_render' }
        },
        {
          title: '入库时间',
          width: 150,
          align: 'center',
          dataIndex: 'create_time'
        },
        {
          title: '备注',
          width: 150,
          align: 'center',
          dataIndex: 'remark'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wechat_refund_order_goods_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.mdl = JSON.parse(Base64.decode(this.$route.query.record || ''))
    if (this.mdl) {
      this.queryParam.order_no = this.mdl.order_no
    }
  }
}
</script>
