import request from '@/utils/request'
const requestApi = {
  wms_wechat_order_list: '/wms/warehouse/wechat_order_list/',
  wms_counter_order_list: '/wms/warehouse/counter_order_list/',
  wms_wechat_order_detail_list: '/wms/warehouse/wechat_order/id/goods/',
  wms_wechat_refund_order_goods_list: '/wms/warehouse/refund_order/goods/',
  wms_counter_order_detail_list: '/wms/warehouse/counter_order/id/goods/',
  wms_wechat_order_pick_list: '/wms/warehouse/wechat_order_pick_list/',
  wms_counter_order_pick_list: '/wms/warehouse/counter_order_pick_list/',
  wms_transfer_inventory_detail_delete: '/wms/warehouse/transfer_inventory_detail/',
  wms_transfer_inventory_detail_export: '/wms/warehouse/transfer_inventory_detail/export/',
  shop_order_pay_method_list: '/shop/back_pay_method/',
  shop_order_pay_method_create: '/shop/back_pay_method/',
  shop_order_pay_method_delete: '/shop/back_pay_method/'
}

export function wechat_order_list (query) {
  return request({
    url: requestApi.wms_wechat_order_list,
    method: 'get',
    params: query
  })
}

export function wechat_order_detail_list (query, order_no) {
  return request({
    url: requestApi.wms_wechat_order_detail_list.replace('id', order_no),
    method: 'get',
    params: query
  })
}

export function wechat_refund_order_goods_list (query) {
  return request({
    url: requestApi.wms_wechat_refund_order_goods_list,
    method: 'get',
    params: query
  })
}

export function wms_counter_order_list (query) {
  return request({
    url: requestApi.wms_counter_order_list,
    method: 'get',
    params: query
  })
}

export function counter_order_detail_list (query, order_no) {
  return request({
    url: requestApi.wms_counter_order_detail_list.replace('id', order_no),
    method: 'get',
    params: query
  })
}

export function wms_wechat_order_pick_list (data) {
  return request({
    url: requestApi.wms_wechat_order_pick_list,
    method: 'post',
    data: data
  })
}

export function wms_counter_order_pick_list (data) {
  return request({
    url: requestApi.wms_counter_order_pick_list,
    method: 'post',
    data: data
  })
}

export function shop_order_pay_method (query) {
  return request({
    url: requestApi.shop_order_pay_method_list,
    method: 'get',
    params: query
  })
}

export function shop_order_pay_method_create (data) {
  return request({
    url: requestApi.shop_order_pay_method_create,
    method: 'post',
    data: data
  })
}

export function shop_order_pay_method_delete (id) {
  return request({
    url: requestApi.shop_order_pay_method_delete + id + '/',
    method: 'delete'
  })
}

export function wmsOrderToWarehouseConfig (query) {
  return request({
    url: '/wms/config/',
    method: 'get',
    params: query
  })
}

export function putWmsOrderToWarehouseConfig (parameter, id) {
  return request({
    url: '/wms/config/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
